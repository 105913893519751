import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import PageHeader from '../components/PageHeader';

interface TermsAndConditionsPageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function TermsAndConditionsPage({
    data,
}: TermsAndConditionsPageProps) {
    const { translate } = useLocale();
    return (
        <div>
            <SEO
                title={
                    data.page.title
                        ? translate(data.page.title)
                        : 'Términos y Condiciones'
                }
            />
            {data.page.title && <PageHeader title={data.page.title} />}
            <Content data={data.page.content} />
        </div>
    );
}

export const query = graphql`
    query TermsAndConditionsPageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "terminos-y-condiciones" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
